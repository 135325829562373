import './bootstrap';
import '../css/app.css';

import { createApp } from 'vue'; // Import Vue 3's createApp function

import HomePageComponent from './Components/HomePageComponent.vue';
import TopicSentenceComponent from './Components/TopicSentenceComponent.vue';
import AiToolsComponent from './Components/AiToolsComponent.vue';
import ErrorPopupComponent from './Components/ErrorPopupComponent.vue';
import headerComponent from './Components/headerComponent.vue';
import ActionButtonsComponent from './Components/ActionButtonsComponent.vue';


/**
 * AI Writer components
 */
import AiWriterComponent from './Components/aiWriter/AiWriterComponent.vue';
import AiWriterProjectComponent from './Components/aiWriter/ProjectComponent.vue';
import ClickOutside from './ClickOutsideDirective'; // Adjust the import path according to your project structure

/* end */
const app = createApp({
    components: {
        HomePageComponent,
        TopicSentenceComponent,
        AiWriterProjectComponent,
        ErrorPopupComponent,
        AiToolsComponent,
        headerComponent,
        AiWriterComponent,
    },
    data(){
        return{
            searchKeyword:''
        };
    }
});
app.directive('click-outside', ClickOutside);
app.mount('#app');
window.addEventListener("load", function(){
    let images=[...document.getElementsByClassName("loadimg")];
    function onIntersection(e){e.forEach(e=>{e.isIntersecting&&(observer.unobserve(e.target),e.target.src=e.target.dataset.src,e.target.alt=e.target.dataset.src,e.target.onload=()=>e.target.classList.add("lazyload"))})}let observer=new IntersectionObserver(onIntersection);images.forEach(e=>observer.observe(e));
});
