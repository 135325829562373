<!-- resources/js/MyComponent.vue -->
<template>
    <div class="block w-full md:flex h-full relative max-w-5xl mx-auto">
        <div class="flex-1 h-auto bg-white border-l ">
            <div v-if="showPopup" class="popup-overlay">
                <div class="bg-white p-4 rounded w-[500px]">
                    <div class="w-full">
                        <button class="p-2" style="display: contents" @click="showPopup = false">
                            <svg class="h-4 w-4 float-right" viewPort="0 0 12 12" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <line x1="1" y1="11"
                                      x2="11" y2="1"
                                      stroke="black"
                                      stroke-width="2"/>
                                <line x1="1" y1="1"
                                      x2="11" y2="11"
                                      stroke="black"
                                      stroke-width="2"/>
                            </svg>
                        </button>
                    </div>
                    <h2 class="text-2xl font-normal flex flex-wrap items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-bg-brand-primary-default h-5 w-5 min-w-fit"><path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"></path><path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3"></path><path d="M9.7 17l4.6 0"></path></svg> Generate Idea </h2>
                    <div class="w-full dropdown inline-block relative">
                        <button class="border-2 border-[#DEE8F9] mt-4 justify-between w-full font-semibold py-2 px-4 rounded inline-flex items-center">
                            <span class="mr-1">{{selectedDocumentType}}</span>
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                        </button>
                        <ul class="dropdown-menu shadow-lg z-10 [&_li]:!bg-white absolute hidden pt-1 min-w-[180px]">
                            <li v-for="(value, key, index)  in documentTypes" :class="[i === 1?'rounded-t': i === (documentTypes.length+1)?'rounded-t':'']" ><a @click="selectedDocumentType = value" class=" hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">{{value}} </a></li>
                        </ul>

                    </div>
                    <input type="text" class="mt-4 rounded w-full" placeholder="I'm writing about..." v-model="rawtext">
                    <button class="rounded bg-violet-500 hover:bg-violet-800 p-2 flex flex-row justify-center font-bold text-white w-full text-center mt-4" @click="createProjectWithParam()">Generate Outline <svg v-if="loading" class="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg></button>
                </div>
            </div>
            <div v-if="showTranslatePopup" class="popup-overlay">
                <div class="bg-white p-4 rounded w-[500px]">
                    <div class="w-full">
                        <button class="p-2" style="display: contents" @click="showTranslatePopup = false">
                            <svg class="h-4 w-4 float-right" viewPort="0 0 12 12" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <line x1="1" y1="11"
                                      x2="11" y2="1"
                                      stroke="black"
                                      stroke-width="2"/>
                                <line x1="1" y1="1"
                                      x2="11" y2="11"
                                      stroke="black"
                                      stroke-width="2"/>
                            </svg>
                        </button>
                    </div>
                    <h2 class="text-2xl font-normal flex flex-wrap items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-bg-brand-primary-default h-5 w-5 min-w-fit"><path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"></path><path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3"></path><path d="M9.7 17l4.6 0"></path></svg> Translate the following text </h2>
                    <div class="w-full dropdown inline-block relative">
                        <button class="border-2 border-[#DEE8F9] mt-4 justify-between w-full font-semibold py-2 px-4 rounded inline-flex items-center">
                            <span class="mr-1">{{selectedTranslateLang}}</span>
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                        </button>
                        <ul class="dropdown-menu shadow-lg z-10 [&_li]:!bg-white absolute hidden pt-1 min-w-[180px]">
                            <li v-for="(value, key, index)  in translateTypes" :class="[i === 1?'rounded-t': i === (translateTypes.length+1)?'rounded-t':'']" ><a @click="selectedTranslateLang = value" class=" hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">{{value}} </a></li>
                        </ul>

                    </div>
                    <input type="text" class="mt-4 rounded w-full" placeholder="I'm writing about..." v-model="rawtext">
                    <button @click="translateFun()" class="rounded bg-violet-500 hover:bg-violet-800 p-2 flex flex-wrap gap-2 justify-center font-bold text-white w-full text-center mt-4">Translate <svg v-if="loading" class="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg></button>
                    <input type="text" class="mt-4 rounded w-full" placeholder="" v-model="translatedText">
                    <button @click="translateUse()" class="rounded bg-violet-500 hover:bg-violet-800 p-2 flex flex-wrap gap-2 justify-center font-bold text-white w-full text-center mt-4">Use this Translation </button>
                </div>
            </div>
            <div ref="quillEditor"></div>
            <div  v-click-outside="hideSidebuttonHelper" >
                <div ref="pointerButton" @click="showSidebuttonHelper" class="pointer-action border-2 border-violet-500 bg-violet-100 py-2 px-0 rounded cursor-pointer" style="position: absolute; right: -30px; display: none;">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M20.9519 3.0481C19.5543 1.65058 17.2885 1.65064 15.8911 3.04825L3.94103 14.9997C3.5347 15.4061 3.2491 15.9172 3.116 16.4762L2.02041 21.0777C1.96009 21.3311 2.03552 21.5976 2.21968 21.7817C2.40385 21.9659 2.67037 22.0413 2.92373 21.981L7.52498 20.8855C8.08418 20.7523 8.59546 20.4666 9.00191 20.0601L20.952 8.10861C22.3493 6.71112 22.3493 4.4455 20.9519 3.0481ZM16.9518 4.10884C17.7634 3.29709 19.0795 3.29705 19.8912 4.10876C20.7028 4.9204 20.7029 6.23632 19.8913 7.04801L19 7.93946L16.0606 5.00012L16.9518 4.10884ZM15 6.06084L17.9394 9.00018L7.94119 18.9995C7.73104 19.2097 7.46668 19.3574 7.17755 19.4263L3.76191 20.2395L4.57521 16.8237C4.64402 16.5346 4.79168 16.2704 5.00175 16.0603L15 6.06084Z"></path></svg>

                    <!--                <button @click="insertParagraph">Insert Paragraph</button>
                                    <button @click="insertHeading">Insert Heading</button>-->
                </div>
                <div ref="sidebuttonhelper" v-if="sidebuttonHelperVar" :style="{ top: sidebuttonHelperVarXY.y + 'px', left: sidebuttonHelperVarXY.x + 'px' }" class=""  style="position: absolute; z-index: 100;">
                    <ul class="shadow-xl [&_li]:!flex [&_li]:!gap-2 [&_li]:!py-1 [&_li]:!px-2 [&_li]:!rounded rounded p-2 bg-white shadow-2xl">
                        <li @click="showPopup = true" class="cursor-pointer hover:bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-bg-brand-primary-default h-5 w-5 min-w-fit"><path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"></path><path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3"></path><path d="M9.7 17l4.6 0"></path></svg> Generate Idea</li>
                        <li class="cursor-pointer hover:bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" fill-rule="evenodd" d="M14.469 3.004a.963.963 0 0 1 1.012.683l1.289 3.921a.253.253 0 0 0 .257.177l3.947-.274a.968.968 0 0 1 .96.63c.147.386.051.855-.308 1.142l-3.966 3.41a.263.263 0 0 0-.087.15l-.895 4.624a.965.965 0 0 1-1.85.19v-.002l-1.44-3.57a.254.254 0 0 0-.214-.16l-3.436-.278c-.929-.063-1.215-1.278-.49-1.808l.003-.001 3.56-2.61a.263.263 0 0 0 .104-.179l.679-5.194.001-.01a.984.984 0 0 1 .874-.84ZM14.42 9.26l.366-2.8.543 1.653c.259.786.997 1.294 1.799 1.238l2.224-.154-2.666 2.293a1.842 1.842 0 0 0-.604 1.047l-.543 2.804-.75-1.858a1.779 1.779 0 0 0-1.498-1.121l-1.92-.155 2.32-1.7c.4-.294.664-.745.73-1.247Z" clip-rule="evenodd"></path><path fill="currentColor" d="m10.67 5.69-1.912.126a.507.507 0 0 1-.512-.354l-.623-1.9a.1.1 0 0 0-.196.023L7.1 6.095a.526.526 0 0 1-.209.357l-1.718 1.26c-.08.058-.046.175.058.187l1.658.138c.19.016.354.14.427.32l.698 1.728c.034.093.172.082.195-.024l.436-2.238a.526.526 0 0 1 .173-.3l1.933-1.658c.069-.059.011-.187-.08-.175Zm-.38 9.575-2.621.184a.507.507 0 0 1-.514-.354l-.86-2.613c-.044-.133-.241-.122-.263.023l-.441 3.37a.526.526 0 0 1-.21.355l-2.327 1.705c-.098.078-.054.234.066.245l2.286.186c.19.016.354.14.427.32l.954 2.365c.055.123.23.111.263-.022l.578-3.002a.526.526 0 0 1 .172-.3l2.578-2.218c.11-.089.044-.256-.088-.244Z"></path></svg> Complete Paragraph</li>
                        <li class="cursor-pointer hover:bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" fill-rule="evenodd" d="M14.469 3.004a.963.963 0 0 1 1.012.683l1.289 3.921a.253.253 0 0 0 .257.177l3.947-.274a.968.968 0 0 1 .96.63c.147.386.051.855-.308 1.142l-3.966 3.41a.263.263 0 0 0-.087.15l-.895 4.624a.965.965 0 0 1-1.85.19v-.002l-1.44-3.57a.254.254 0 0 0-.214-.16l-3.436-.278c-.929-.063-1.215-1.278-.49-1.808l.003-.001 3.56-2.61a.263.263 0 0 0 .104-.179l.679-5.194.001-.01a.984.984 0 0 1 .874-.84ZM14.42 9.26l.366-2.8.543 1.653c.259.786.997 1.294 1.799 1.238l2.224-.154-2.666 2.293a1.842 1.842 0 0 0-.604 1.047l-.543 2.804-.75-1.858a1.779 1.779 0 0 0-1.498-1.121l-1.92-.155 2.32-1.7c.4-.294.664-.745.73-1.247Z" clip-rule="evenodd"></path><path fill="currentColor" d="m10.67 5.69-1.912.126a.507.507 0 0 1-.512-.354l-.623-1.9a.1.1 0 0 0-.196.023L7.1 6.095a.526.526 0 0 1-.209.357l-1.718 1.26c-.08.058-.046.175.058.187l1.658.138c.19.016.354.14.427.32l.698 1.728c.034.093.172.082.195-.024l.436-2.238a.526.526 0 0 1 .173-.3l1.933-1.658c.069-.059.011-.187-.08-.175Zm-.38 9.575-2.621.184a.507.507 0 0 1-.514-.354l-.86-2.613c-.044-.133-.241-.122-.263.023l-.441 3.37a.526.526 0 0 1-.21.355l-2.327 1.705c-.098.078-.054.234.066.245l2.286.186c.19.016.354.14.427.32l.954 2.365c.055.123.23.111.263-.022l.578-3.002a.526.526 0 0 1 .172-.3l2.578-2.218c.11-.089.044-.256-.088-.244Z"></path></svg> Start New Paragraph</li>
                        <li class="cursor-pointer hover:bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" fill-rule="evenodd" d="M14.469 3.004a.963.963 0 0 1 1.012.683l1.289 3.921a.253.253 0 0 0 .257.177l3.947-.274a.968.968 0 0 1 .96.63c.147.386.051.855-.308 1.142l-3.966 3.41a.263.263 0 0 0-.087.15l-.895 4.624a.965.965 0 0 1-1.85.19v-.002l-1.44-3.57a.254.254 0 0 0-.214-.16l-3.436-.278c-.929-.063-1.215-1.278-.49-1.808l.003-.001 3.56-2.61a.263.263 0 0 0 .104-.179l.679-5.194.001-.01a.984.984 0 0 1 .874-.84ZM14.42 9.26l.366-2.8.543 1.653c.259.786.997 1.294 1.799 1.238l2.224-.154-2.666 2.293a1.842 1.842 0 0 0-.604 1.047l-.543 2.804-.75-1.858a1.779 1.779 0 0 0-1.498-1.121l-1.92-.155 2.32-1.7c.4-.294.664-.745.73-1.247Z" clip-rule="evenodd"></path><path fill="currentColor" d="m10.67 5.69-1.912.126a.507.507 0 0 1-.512-.354l-.623-1.9a.1.1 0 0 0-.196.023L7.1 6.095a.526.526 0 0 1-.209.357l-1.718 1.26c-.08.058-.046.175.058.187l1.658.138c.19.016.354.14.427.32l.698 1.728c.034.093.172.082.195-.024l.436-2.238a.526.526 0 0 1 .173-.3l1.933-1.658c.069-.059.011-.187-.08-.175Zm-.38 9.575-2.621.184a.507.507 0 0 1-.514-.354l-.86-2.613c-.044-.133-.241-.122-.263.023l-.441 3.37a.526.526 0 0 1-.21.355l-2.327 1.705c-.098.078-.054.234.066.245l2.286.186c.19.016.354.14.427.32l.954 2.365c.055.123.23.111.263-.022l.578-3.002a.526.526 0 0 1 .172-.3l2.578-2.218c.11-.089.044-.256-.088-.244Z"></path></svg> Add Example</li>
                        <li class="cursor-pointer hover:bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" fill-rule="evenodd" d="M14.469 3.004a.963.963 0 0 1 1.012.683l1.289 3.921a.253.253 0 0 0 .257.177l3.947-.274a.968.968 0 0 1 .96.63c.147.386.051.855-.308 1.142l-3.966 3.41a.263.263 0 0 0-.087.15l-.895 4.624a.965.965 0 0 1-1.85.19v-.002l-1.44-3.57a.254.254 0 0 0-.214-.16l-3.436-.278c-.929-.063-1.215-1.278-.49-1.808l.003-.001 3.56-2.61a.263.263 0 0 0 .104-.179l.679-5.194.001-.01a.984.984 0 0 1 .874-.84ZM14.42 9.26l.366-2.8.543 1.653c.259.786.997 1.294 1.799 1.238l2.224-.154-2.666 2.293a1.842 1.842 0 0 0-.604 1.047l-.543 2.804-.75-1.858a1.779 1.779 0 0 0-1.498-1.121l-1.92-.155 2.32-1.7c.4-.294.664-.745.73-1.247Z" clip-rule="evenodd"></path><path fill="currentColor" d="m10.67 5.69-1.912.126a.507.507 0 0 1-.512-.354l-.623-1.9a.1.1 0 0 0-.196.023L7.1 6.095a.526.526 0 0 1-.209.357l-1.718 1.26c-.08.058-.046.175.058.187l1.658.138c.19.016.354.14.427.32l.698 1.728c.034.093.172.082.195-.024l.436-2.238a.526.526 0 0 1 .173-.3l1.933-1.658c.069-.059.011-.187-.08-.175Zm-.38 9.575-2.621.184a.507.507 0 0 1-.514-.354l-.86-2.613c-.044-.133-.241-.122-.263.023l-.441 3.37a.526.526 0 0 1-.21.355l-2.327 1.705c-.098.078-.054.234.066.245l2.286.186c.19.016.354.14.427.32l.954 2.365c.055.123.23.111.263-.022l.578-3.002a.526.526 0 0 1 .172-.3l2.578-2.218c.11-.089.044-.256-.088-.244Z"></path></svg> Add Counter Example</li>
                        <li class="cursor-pointer hover:bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" fill-rule="evenodd" d="M14.469 3.004a.963.963 0 0 1 1.012.683l1.289 3.921a.253.253 0 0 0 .257.177l3.947-.274a.968.968 0 0 1 .96.63c.147.386.051.855-.308 1.142l-3.966 3.41a.263.263 0 0 0-.087.15l-.895 4.624a.965.965 0 0 1-1.85.19v-.002l-1.44-3.57a.254.254 0 0 0-.214-.16l-3.436-.278c-.929-.063-1.215-1.278-.49-1.808l.003-.001 3.56-2.61a.263.263 0 0 0 .104-.179l.679-5.194.001-.01a.984.984 0 0 1 .874-.84ZM14.42 9.26l.366-2.8.543 1.653c.259.786.997 1.294 1.799 1.238l2.224-.154-2.666 2.293a1.842 1.842 0 0 0-.604 1.047l-.543 2.804-.75-1.858a1.779 1.779 0 0 0-1.498-1.121l-1.92-.155 2.32-1.7c.4-.294.664-.745.73-1.247Z" clip-rule="evenodd"></path><path fill="currentColor" d="m10.67 5.69-1.912.126a.507.507 0 0 1-.512-.354l-.623-1.9a.1.1 0 0 0-.196.023L7.1 6.095a.526.526 0 0 1-.209.357l-1.718 1.26c-.08.058-.046.175.058.187l1.658.138c.19.016.354.14.427.32l.698 1.728c.034.093.172.082.195-.024l.436-2.238a.526.526 0 0 1 .173-.3l1.933-1.658c.069-.059.011-.187-.08-.175Zm-.38 9.575-2.621.184a.507.507 0 0 1-.514-.354l-.86-2.613c-.044-.133-.241-.122-.263.023l-.441 3.37a.526.526 0 0 1-.21.355l-2.327 1.705c-.098.078-.054.234.066.245l2.286.186c.19.016.354.14.427.32l.954 2.365c.055.123.23.111.263-.022l.578-3.002a.526.526 0 0 1 .172-.3l2.578-2.218c.11-.089.044-.256-.088-.244Z"></path></svg> Offer New Viewpoint</li>
                        <li class="cursor-pointer hover:bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" fill-rule="evenodd" d="M14.469 3.004a.963.963 0 0 1 1.012.683l1.289 3.921a.253.253 0 0 0 .257.177l3.947-.274a.968.968 0 0 1 .96.63c.147.386.051.855-.308 1.142l-3.966 3.41a.263.263 0 0 0-.087.15l-.895 4.624a.965.965 0 0 1-1.85.19v-.002l-1.44-3.57a.254.254 0 0 0-.214-.16l-3.436-.278c-.929-.063-1.215-1.278-.49-1.808l.003-.001 3.56-2.61a.263.263 0 0 0 .104-.179l.679-5.194.001-.01a.984.984 0 0 1 .874-.84ZM14.42 9.26l.366-2.8.543 1.653c.259.786.997 1.294 1.799 1.238l2.224-.154-2.666 2.293a1.842 1.842 0 0 0-.604 1.047l-.543 2.804-.75-1.858a1.779 1.779 0 0 0-1.498-1.121l-1.92-.155 2.32-1.7c.4-.294.664-.745.73-1.247Z" clip-rule="evenodd"></path><path fill="currentColor" d="m10.67 5.69-1.912.126a.507.507 0 0 1-.512-.354l-.623-1.9a.1.1 0 0 0-.196.023L7.1 6.095a.526.526 0 0 1-.209.357l-1.718 1.26c-.08.058-.046.175.058.187l1.658.138c.19.016.354.14.427.32l.698 1.728c.034.093.172.082.195-.024l.436-2.238a.526.526 0 0 1 .173-.3l1.933-1.658c.069-.059.011-.187-.08-.175Zm-.38 9.575-2.621.184a.507.507 0 0 1-.514-.354l-.86-2.613c-.044-.133-.241-.122-.263.023l-.441 3.37a.526.526 0 0 1-.21.355l-2.327 1.705c-.098.078-.054.234.066.245l2.286.186c.19.016.354.14.427.32l.954 2.365c.055.123.23.111.263-.022l.578-3.002a.526.526 0 0 1 .172-.3l2.578-2.218c.11-.089.044-.256-.088-.244Z"></path></svg> Write Concusion</li>
                    </ul>
                </div>
            </div>

            <div v-if="showContextMenu" :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }" class="context-menu">
                <ul class="shadow-xl [&_li]:!flex [&_li]:!gap-2 [&_li]:!py-1 [&_li]:!px-2 [&_li]:!rounded rounded p-2 bg-white">
                    <li class="cursor-pointer hover:bg-gray-100" @click="rewriteSelectedText"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" fill-rule="evenodd" d="M14.469 3.004a.963.963 0 0 1 1.012.683l1.289 3.921a.253.253 0 0 0 .257.177l3.947-.274a.968.968 0 0 1 .96.63c.147.386.051.855-.308 1.142l-3.966 3.41a.263.263 0 0 0-.087.15l-.895 4.624a.965.965 0 0 1-1.85.19v-.002l-1.44-3.57a.254.254 0 0 0-.214-.16l-3.436-.278c-.929-.063-1.215-1.278-.49-1.808l.003-.001 3.56-2.61a.263.263 0 0 0 .104-.179l.679-5.194.001-.01a.984.984 0 0 1 .874-.84ZM14.42 9.26l.366-2.8.543 1.653c.259.786.997 1.294 1.799 1.238l2.224-.154-2.666 2.293a1.842 1.842 0 0 0-.604 1.047l-.543 2.804-.75-1.858a1.779 1.779 0 0 0-1.498-1.121l-1.92-.155 2.32-1.7c.4-.294.664-.745.73-1.247Z" clip-rule="evenodd"></path><path fill="currentColor" d="m10.67 5.69-1.912.126a.507.507 0 0 1-.512-.354l-.623-1.9a.1.1 0 0 0-.196.023L7.1 6.095a.526.526 0 0 1-.209.357l-1.718 1.26c-.08.058-.046.175.058.187l1.658.138c.19.016.354.14.427.32l.698 1.728c.034.093.172.082.195-.024l.436-2.238a.526.526 0 0 1 .173-.3l1.933-1.658c.069-.059.011-.187-.08-.175Zm-.38 9.575-2.621.184a.507.507 0 0 1-.514-.354l-.86-2.613c-.044-.133-.241-.122-.263.023l-.441 3.37a.526.526 0 0 1-.21.355l-2.327 1.705c-.098.078-.054.234.066.245l2.286.186c.19.016.354.14.427.32l.954 2.365c.055.123.23.111.263-.022l.578-3.002a.526.526 0 0 1 .172-.3l2.578-2.218c.11-.089.044-.256-.088-.244Z"></path></svg> Rewrite</li>
                    <li class="cursor-pointer hover:bg-gray-100" @click="shortSelectedText"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" d="M5.363 15.637a.9.9 0 0 1 0-1.273L6.826 12.9H3a.9.9 0 0 1 0-1.8h3.827L5.363 9.637a.9.9 0 1 1 1.273-1.273l3 3a.896.896 0 0 1 0 1.273l-3 3a.9.9 0 0 1-1.273 0Zm12 0a.9.9 0 1 0 1.273-1.273L17.172 12.9h3.827a.9.9 0 1 0 0-1.8h-3.827l1.463-1.463a.9.9 0 1 0-1.272-1.273l-2.996 2.995a.88.88 0 0 0-.183.26.898.898 0 0 0 .183 1.023l2.996 2.995Z"></path></svg> Short Text</li>
                    <li class="cursor-pointer hover:bg-gray-100" @click="extendSelectedText"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" d="M18.635 8.264a.9.9 0 0 0-1.273 1.273L18.826 11h-3.827a.9.9 0 1 0 0 1.8h3.827l-1.464 1.464a.9.9 0 0 0 1.273 1.273l2.995-2.996a.949.949 0 0 0 .184-.259.896.896 0 0 0-.184-1.023l-2.995-2.995Zm-12 0a.9.9 0 0 1 0 1.273L5.172 11h3.827a.9.9 0 0 1 0 1.8H5.17l1.464 1.464a.9.9 0 1 1-1.273 1.272l-3-3a.896.896 0 0 1-.21-.94.897.897 0 0 1 .21-.332l3-3a.9.9 0 0 1 1.273 0Z"></path></svg> Expand Text</li>
                    <li class="cursor-pointer hover:bg-gray-100" @click="rewriteSelectedText"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"  class="inline-block h-5 w-5"><path stroke="currentColor" stroke-linejoin="round" stroke-width="1.25" d="m10 3.333.513 2.278a5.128 5.128 0 0 0 3.876 3.876l2.278.513-2.278.513a5.128 5.128 0 0 0-3.876 3.876L10 16.667l-.513-2.278a5.128 5.128 0 0 0-3.876-3.876L3.333 10l2.278-.513A5.128 5.128 0 0 0 9.487 5.61L10 3.333Z"></path></svg> Improve Text</li>
                    <li class="cursor-pointer hover:bg-gray-100" @click="addTextSelectedText"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-bg-brand-primary-default h-5 w-5 min-w-fit"><path d="M12 9v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-3v-6h3z"></path><path d="M3 9v6"></path><path d="M6 9v6"></path></svg>Continue Writing</li>
                    <li class="cursor-pointer hover:bg-gray-100" @click="translateSelectedText"><svg  class="inline-block h-5 w-5" focusable="false" aria-hidden="true" viewBox="0 0 20 20" width="24" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M10.1667 18L13.7292 8.5H15.4375L19 18H17.3542L16.5 15.5625H12.6667L11.8125 18H10.1667ZM13.1667 14.1875H16L14.625 10.25H14.5417L13.1667 14.1875ZM3.52083 15.5L2.47917 14.4375L6.47917 10.4792C5.97917 9.95139 5.51389 9.40278 5.08333 8.83333C4.65278 8.26389 4.27778 7.64583 3.95833 6.97917H5.66667C5.91667 7.42361 6.20139 7.84028 6.52083 8.22917C6.84028 8.61806 7.16667 9 7.5 9.375C8.02778 8.79167 8.51389 8.18403 8.95833 7.55208C9.40278 6.92014 9.76389 6.23611 10.0417 5.5H1V4H6.75V2H8.25V4H14V5.5H11.625C11.3333 6.45833 10.9097 7.34375 10.3542 8.15625C9.79861 8.96875 9.1875 9.74306 8.52083 10.4792L10.4167 12.3542L9.83333 13.8958L7.5 11.5625L3.52083 15.5Z" fill="evenodd"></path></svg> Translate</li>
                </ul>
            </div>
            <div ref="popup" class="popup border-2 rounded border-gray-100 min-w-max shadow-xl flex flex-row rounded bg-white p-1"  style="display: none; position: absolute; z-index: 100;">
                <!-- Popup content goes here -->
                    <div class="flex py-1 px-2 border-r border-1 cursor-pointer hover:bg-gray-100" @click="rewriteSelectedText"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" fill-rule="evenodd" d="M14.469 3.004a.963.963 0 0 1 1.012.683l1.289 3.921a.253.253 0 0 0 .257.177l3.947-.274a.968.968 0 0 1 .96.63c.147.386.051.855-.308 1.142l-3.966 3.41a.263.263 0 0 0-.087.15l-.895 4.624a.965.965 0 0 1-1.85.19v-.002l-1.44-3.57a.254.254 0 0 0-.214-.16l-3.436-.278c-.929-.063-1.215-1.278-.49-1.808l.003-.001 3.56-2.61a.263.263 0 0 0 .104-.179l.679-5.194.001-.01a.984.984 0 0 1 .874-.84ZM14.42 9.26l.366-2.8.543 1.653c.259.786.997 1.294 1.799 1.238l2.224-.154-2.666 2.293a1.842 1.842 0 0 0-.604 1.047l-.543 2.804-.75-1.858a1.779 1.779 0 0 0-1.498-1.121l-1.92-.155 2.32-1.7c.4-.294.664-.745.73-1.247Z" clip-rule="evenodd"></path><path fill="currentColor" d="m10.67 5.69-1.912.126a.507.507 0 0 1-.512-.354l-.623-1.9a.1.1 0 0 0-.196.023L7.1 6.095a.526.526 0 0 1-.209.357l-1.718 1.26c-.08.058-.046.175.058.187l1.658.138c.19.016.354.14.427.32l.698 1.728c.034.093.172.082.195-.024l.436-2.238a.526.526 0 0 1 .173-.3l1.933-1.658c.069-.059.011-.187-.08-.175Zm-.38 9.575-2.621.184a.507.507 0 0 1-.514-.354l-.86-2.613c-.044-.133-.241-.122-.263.023l-.441 3.37a.526.526 0 0 1-.21.355l-2.327 1.705c-.098.078-.054.234.066.245l2.286.186c.19.016.354.14.427.32l.954 2.365c.055.123.23.111.263-.022l.578-3.002a.526.526 0 0 1 .172-.3l2.578-2.218c.11-.089.044-.256-.088-.244Z"></path></svg> Rewrite 1</div>
                    <div class="flex py-1 px-2 border-r border-1 cursor-pointer hover:bg-gray-100" @click="shortSelectedText"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" d="M5.363 15.637a.9.9 0 0 1 0-1.273L6.826 12.9H3a.9.9 0 0 1 0-1.8h3.827L5.363 9.637a.9.9 0 1 1 1.273-1.273l3 3a.896.896 0 0 1 0 1.273l-3 3a.9.9 0 0 1-1.273 0Zm12 0a.9.9 0 1 0 1.273-1.273L17.172 12.9h3.827a.9.9 0 1 0 0-1.8h-3.827l1.463-1.463a.9.9 0 1 0-1.272-1.273l-2.996 2.995a.88.88 0 0 0-.183.26.898.898 0 0 0 .183 1.023l2.996 2.995Z"></path></svg> Short Text</div>
                    <div class="flex py-1 px-2 border-r border-1 cursor-pointer hover:bg-gray-100" @click="extendSelectedText"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block h-5 w-5"><path fill="currentColor" d="M18.635 8.264a.9.9 0 0 0-1.273 1.273L18.826 11h-3.827a.9.9 0 1 0 0 1.8h3.827l-1.464 1.464a.9.9 0 0 0 1.273 1.273l2.995-2.996a.949.949 0 0 0 .184-.259.896.896 0 0 0-.184-1.023l-2.995-2.995Zm-12 0a.9.9 0 0 1 0 1.273L5.172 11h3.827a.9.9 0 0 1 0 1.8H5.17l1.464 1.464a.9.9 0 1 1-1.273 1.272l-3-3a.896.896 0 0 1-.21-.94.897.897 0 0 1 .21-.332l3-3a.9.9 0 0 1 1.273 0Z"></path></svg> Expand Text</div>
                    <div class="flex py-1 px-2 border-r border-1 cursor-pointer hover:bg-gray-100" @click="rewriteSelectedText"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"  class="inline-block h-5 w-5"><path stroke="currentColor" stroke-linejoin="round" stroke-width="1.25" d="m10 3.333.513 2.278a5.128 5.128 0 0 0 3.876 3.876l2.278.513-2.278.513a5.128 5.128 0 0 0-3.876 3.876L10 16.667l-.513-2.278a5.128 5.128 0 0 0-3.876-3.876L3.333 10l2.278-.513A5.128 5.128 0 0 0 9.487 5.61L10 3.333Z"></path></svg> Improve Text</div>
                    <div class="flex py-1 px-2 border-r border-1 cursor-pointer hover:bg-gray-100" @click="addTextSelectedText"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-bg-brand-primary-default h-5 w-5 min-w-fit"><path d="M12 9v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-3v-6h3z"></path><path d="M3 9v6"></path><path d="M6 9v6"></path></svg>Continue Writing</div>
                    <div class="flex py-1 px-2 cursor-pointer hover:bg-gray-100" @click="translateSelectedText"><svg class="inline-block h-5 w-5" focusable="false" aria-hidden="true" viewBox="0 0 20 20" width="24" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M10.1667 18L13.7292 8.5H15.4375L19 18H17.3542L16.5 15.5625H12.6667L11.8125 18H10.1667ZM13.1667 14.1875H16L14.625 10.25H14.5417L13.1667 14.1875ZM3.52083 15.5L2.47917 14.4375L6.47917 10.4792C5.97917 9.95139 5.51389 9.40278 5.08333 8.83333C4.65278 8.26389 4.27778 7.64583 3.95833 6.97917H5.66667C5.91667 7.42361 6.20139 7.84028 6.52083 8.22917C6.84028 8.61806 7.16667 9 7.5 9.375C8.02778 8.79167 8.51389 8.18403 8.95833 7.55208C9.40278 6.92014 9.76389 6.23611 10.0417 5.5H1V4H6.75V2H8.25V4H14V5.5H11.625C11.3333 6.45833 10.9097 7.34375 10.3542 8.15625C9.79861 8.96875 9.1875 9.74306 8.52083 10.4792L10.4167 12.3542L9.83333 13.8958L7.5 11.5625L3.52083 15.5Z" fill="evenodd"></path></svg> Translate</div>
            </div>
        </div>


    </div>
</template>

<script>
import Quill from 'quill';
import { ref } from "vue";
import axios from "axios";
export default {
    components: {
    },
    props: {
        translations: Object,
        type: String|null,
        input: String|null,
    },
    data() {
        return {
            showTranslatePopup: false,
            translatedText: '',
            selectedTranslateLang: 'English',
            translateTypes: [
                'German',
                'Chinese',
                'Danish',
                'Spanish',
            ],selectedDocumentType: 'Research Paper',
            documentTypes: [
                'Research Paper',
                'BLog',
                'Essay ',
                'Write Story',
            ],
            range: null,
            showPopup: false,
            userInput: '',
            keywords: '',
            creativity: '',
            editortext: null,
            loading: false,
            sitekey:'0x4AAAAAAAfG90yDOhb1v7bZ',
            token: null,
            rawtext:'',
            showContextMenu: false,
            menuPosition: { x: 0, y: 0 },
            showSelectionOptions: false,
            sidebuttonHelperVar: false,
            sidebuttonHelperVarXY: { x: 0, y: 0 },
        };
    },
    mounted(){
        console.log(this.input+this.type)
        if(this.input){
            this.callFun('outline',{
                type: this.type,
                input: this.input,
            });
        }
        this.$nextTick(() => {
            this.initializeQuill();

        });
        // Add a right-click event listener to the Quill editor container
    },
    beforeMount() {
        if (this.$refs.quillEditor) {
            this.$refs.quillEditor.removeEventListener('contextmenu', this.onEditorRightClick);
        }
    },
    methods:{

        createProjectWithParam(){
            this.callFun('outline',{
                type: this.selectedDocumentType,
                input: this.rawtext,
            });
        },
        showSidebuttonHelper() {
            console.log('showSidebuttonHelper')
            this.sidebuttonHelperVar = !this.sidebuttonHelperVar;
        },
        hideSidebuttonHelper() {
            console.log('clicked')
            if(this.sidebuttonHelperVar){
                console.log('hideSidebuttonHelper')
                this.sidebuttonHelperVar = false;
            }
        },
        onChildMenuOptionSelect(option) {
            console.log('Selected child option:', option);
            this.showContextMenu = false; // Hide the menu
        },
        closeContextMenu() {
            this.showContextMenu = false;
        },
        onEditorRightClick(event) {
            event.preventDefault(); // Prevent the browser's default context menu

            const selection = this.quill.getSelection();
            let selectedText = "";
            if (selection && selection.length > 0) {
                selectedText = this.quill.getText(selection.index, selection.length);
                console.log("Selected text:", selectedText);
                // Show options relevant to text selection
                this.showSelectionOptions = true; // Assume you have this data property to control the display of selection-specific options
            } else {
                // Show options for adding new text as no text is selected
                this.showSelectionOptions = false;
            }

            console.log(event);
            this.menuPosition = { x: (event.offsetX + 10), y: (event.pageY - 80) };
            this.showContextMenu = true;
        },
        onMenuOptionSelect(option) {
            this.showContextMenu = false; // Hide the context menu

            if (option === 'copy') {
                // Handle the copy operation
                const selection = this.quill.getSelection();
                if (selection) {
                    const text = this.quill.getText(selection.index, selection.length);
                    navigator.clipboard.writeText(text).then(() => {
                        console.log("Text copied to clipboard");
                    }).catch(err => {
                        console.error('Could not copy text: ', err);
                    });
                }
            } else if (option === 'addText') {
                // Add new text at the current cursor position or at the end if there's no selection
                const position = this.quill.getSelection() ? this.quill.getSelection().index : this.quill.getLength();
                this.quill.insertText(position, 'New Text', 'user');
            }

            // Handle other options as needed
        },
        closePopup(event) {
            console.log('closePopup')
            console.log(event)
            event.stopPropagation();
            this.showPopup = false; // Hide the popup
            console.log(this.showPopup);
            // Handle the user input, e.g., log to console or perform an action
            console.log('User input:');
            this.$forceUpdate();
        },
        initializeQuill() {
            const toolbarOptions = [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'align': [] }],
                ['clean']
            ];
            this.quill = new Quill(this.$refs.quillEditor, {
                theme: 'snow',
                modules: {
                    toolbar: toolbarOptions,
                },
                // Other Quill options...
            });
            // this.addCustomToolbarButton();
            this.quill.on('selection-change', (range, oldRange, source) => {

                if (range && range.length !== 0) {
                    this.showPopups(range);
                } else {
                    this.hidePopup();
                }
                if (range) {
                    this.updatePointerButtonPosition(range);
                    this.actionSidebarPopup(range);
                } else {
                    //this.$refs.pointerButton.style.display = 'none'; // Hide button when no selection
                }
            });

            this.$refs.quillEditor.addEventListener('contextmenu', this.onEditorRightClick);
            // Add a click event listener to the document to close the context menu when clicking outside
            document.addEventListener('click', this.closeContextMenu);
        },
        actionSidebarPopup(range) {
            const bounds = this.quill.getBounds(range.index, range.length);
            // Calculate the position of the popup. This is a simple example; adjust as needed.
            const popupLeft = bounds.left + bounds.width / 3; // Center the popup above the selection
            const popupTop = bounds.top - 5; // Position the popup a little above the selection

            this.sidebuttonHelperVarXY = { x: popupLeft, y:popupTop };
        },
        showPopups(range) {
            this.range = range;
            const bounds = this.quill.getBounds(range.index, range.length);
            // Calculate the position of the popup. This is a simple example; adjust as needed.
            const popupLeft = bounds.left + bounds.width / 3; // Center the popup above the selection
            const popupTop = bounds.top - 5; // Position the popup a little above the selection

            // Set the position of your popup element
            // Assume you have a popup element with the ref 'popup'
            const popupElement = this.$refs.popup;
            popupElement.style.left = `${popupLeft}px`;
            popupElement.style.top = `${popupTop}px`;

            // Show the popup
            popupElement.style.display = 'flex';

            // Show the popup
            //sidebuttonHelper.style.display = 'flex';
        },
        hidePopup() {
            // Hide the popup
            const popupElement = this.$refs.popup;
            popupElement.style.display = 'none';
        },
        onPopupAction() {
            let range = this.quill.getSelection();
            if (range) {
                let text = this.quill.getText(range.index, range.length);
                // Your logic to modify the text
                let modifiedText = `Modified: ${text}`;
                this.quill.deleteText(range.index, range.length);
                this.quill.insertText(range.index, modifiedText);

                // Hide the popup after action
                this.hidePopup();
            }
        },
        addCustomToolbarButton() {
            // Select the toolbar container
            let toolbar = this.quill.getModule('toolbar').container;
            // Create a new button element
            let button = document.createElement('button');
            button.classList.add('rewrite-btn'); // Set your button label or icon here
            button.innerHTML = 'Rewrite'; // Set your button label or icon here
            // Append the custom button to the toolbar
            toolbar.appendChild(button);

            // Add an event listener to the custom button for its functionality
            button.addEventListener('click', () => {
                this.rewriteSelectedText();
            });
            let buttonp = document.createElement('button');
            buttonp.classList.add('rewrite-btn'); // Set your button label or icon here
            buttonp.innerHTML = 'Paraphrase'; // Set your button label or icon here
            // Append the custom button to the toolbar
            toolbar.appendChild(buttonp);

            // Add an event listener to the custom button for its functionality
            buttonp.addEventListener('click', () => {
                this.rewriteSelectedText();
            });
        },
        getTextRange() {
            // Get the entire editor text.
            const text = this.quill.getText();

            // Calculate the start and end of the selection.
            let start = this.range.index;
            let end = this.range.index + this.range.length;

            // Function to check if a character is a sentence boundary.
            const isSentenceBoundary = (char) => ['.', '?', '!', '\n'].includes(char);

            // Expand the start backwards to the nearest sentence boundary or list marker.
            while (start > 0 && !isSentenceBoundary(text[start - 1])) {
                start--;
            }

            // Expand the end forwards to the nearest sentence boundary or list marker.
            while (end < text.length && !isSentenceBoundary(text[end])) {
                end++;
            }

            // Adjust end to include the boundary character if not at the very end of text and it's punctuation.
            if (end < text.length && ['.', '?', '!'].includes(text[end])) {
                end++;
            }
            this.range.index = start;
            this.range.length = end - start;
            // Return the expanded range's text, including full sentences before and after.
            return this.quill.getText(start, end - start);
        },
        rewriteSelectedText() {
            console.log('rewriteSelectedText')
            let text = this.getTextRange();
            this.callFun('rewrite',{input:text});
        },
        shortSelectedText() {
            console.log('shortSelectedText')
            let text = this.getTextRange();
            this.callFun('shortText',{input:text})
        },
        extendSelectedText() {
            console.log('extendSelectedText')
            let text = this.getTextRange();
            this.callFun('extendText',{input:text})
        },
        addTextSelectedText() {
            console.log('addTextSelectedText')
            let text = this.getTextRange();
            this.callFun('addText',{input:text})
        },
        translateSelectedText() {
            console.log('translateSelectedText')
            this.rawtext = this.getTextRange();
            this.showTranslatePopup = true;
            //this.callFun('translateText',{input:text})
        },
        translateFun() {
            console.log('translateFun')
            this.rawtext = this.getTextRange();
            this.callFun('translateText',{input:' Translate to '+this.selectedTranslateLang+' \n '+this.rawtext})
        },
        translateUse() {
            console.log('translateUse')
            this.quill.deleteText(this.range.index, this.range.length);
            this.quill.insertText(this.range.index, this.translatedText);
        },
        insertParagraph() {
            const textToInsert = '\nNew Paragraph\n';
            this.insertTextAtCursor(textToInsert, 'paragraph');
        },

        insertHeading() {
            const textToInsert = '\nNew Heading\n';
            this.insertTextAtCursor(textToInsert, 'heading', 2); // 2 is the heading level, adjust as needed
        },

        insertTextAtCursor(text, type, level = 1) {
            const range = this.quill.getSelection();
            if (range) {
                const position = range.index === 0 ? range.index : range.index + 1;
                this.quill.insertText(position, text);
                if (type === 'heading') {
                    this.quill.formatText(position, text.length, { header: level });
                }
                this.quill.setSelection(position + text.length);
            }
        },
        updatePointerButtonPosition(range) {
            const bounds = this.quill.getBounds(range.index);
            const editorRect = this.$refs.quillEditor.getBoundingClientRect();
            let scrollTop = this.$refs.quillEditor.querySelector('.ql-editor').scrollTop; // Get the current scroll position
            console.log(scrollTop)
            scrollTop = scrollTop + 70;
            console.log(scrollTop)
            // Adjust the button's top position based on the editor's scroll position
            this.$refs.pointerButton.style.top = `${bounds.top + editorRect.top - scrollTop}px`;
            this.$refs.pointerButton.style.display = 'block'; // Show the button
        },
        rewriteText(text) {
            console.log('rewriteText')
            console.log(text)
            let rewrittenText = this.callFun('rewrite',{input:text})
            // Your text rewriting logic here
            //return this.callFun('rewrite',{input:text});
        },
        doParaphrase() {
            this.loading = true;
            axios
                .post("/tool/rhymeGenerater", {
                    capcha_token: this.token,
                    keywords: this.keywords,
                    creativity: this.creativity,
                    lang: 'english',
                    mode: 'creative',
                })
                .then(response=>{
                    this.loading = false;
                    if (response.data.content.length){
                        this.editortext = response.data.content;
                    }
                    turnstile.reset('#cf-container')
                })
                .catch(e => {
                    let message = "something went wrong please try again.";
                    if(e.response.status == 422) {
                        message = e.response.data.message;
                    }
                    alert(message);
                    this.loading =false;
                    turnstile.reset('#cf-container')
                })
        },
        callFun(slug,obj) {
            this.loading = true;
            axios
                .post("/writing-tool/"+slug, obj)
                .then(response=>{
                    this.loading = false;
                    if (response.data.content.length){
                        this.editortext = response.data.content;
                        if(slug == 'outline'){
                            this.quill.container.firstChild.innerHTML = response.data.content;
                            this.showPopup = false;
                        }
                        if(slug == 'rewrite' || slug == 'shortText' || slug == 'extendText'){
                            let rewrittenText =  response.data.content;
                            this.quill.deleteText(this.range.index, this.range.length);
                            this.quill.insertText(this.range.index, rewrittenText);
                        }
                        if(slug == 'addText'){
                            let rewrittenText =  response.data.content;
                            this.quill.deleteText(this.range.index, this.range.length);
                            this.quill.insertText(this.range.index, rewrittenText);
                        }
                        if(slug == 'translateText'){
                            this.translatedText =  response.data.content;
                        }
                    }
                })
                .catch(e => {
                    let message = "something went wrong please try again.";
                    if(e.response.status == 422) {
                        message = e.response.data.message;
                    }
                    alert(message);
                    this.loading =false;
                })
        }
    }
};

</script>
<style>
.ql-editor {
    height: 100% !important;
}
.ql-editor p{
    font-size: initial;
}
.ql-container {
    height: auto !important;
}
.ql-container.ql-snow {
    border: none!important;
}
.ql-toolbar.ql-snow{
    border: none!important;
    border-bottom: 1px solid rgb(229 231 235);
}
.rewrite-btn{
    padding: 3px !important;
    width: 70px !important;
}
.pointer-action-button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.ql-editor {
    min-height: 450px; /* Set your desired default height */

}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

.popup {

}

.popup h2 {
    margin-top: 0;
}

.popup input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.popup button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.context-menu {
    position: absolute;
    z-index: 1000; /* Ensure it appears above other content */
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.context-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.context-menu ul li {
    padding: 8px 12px;
    cursor: pointer;
}

.context-menu ul li:hover {
    background-color: #f0f0f0;
}

.context-menu ul li ul {
    display: none;
}

.context-menu ul li:hover ul {
    display: block; /* Show child menu on hover */
    position: absolute;
    left: 100%;
    top: 0;
}
</style>
